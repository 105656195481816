import { Routes, Route } from "react-router-dom"
import FidelityRothIRA  from "./components/Onboarding/Step1/accountTypes/FidelityRothIRA";
import FidelityBrokerage  from "./components/Onboarding/Step1/accountTypes/fidelityBrokerage";
import Goal from "./components/Onboarding/Step1/goal"
import BankAccount from "./components/Onboarding/Step2/ConnectBank/BankAccount.js";
import BankTrans from "./components/Onboarding/Step2/BankTrans";
import FundingYourFuture from "./components/Onboarding/Step3/FidelityPlan";
import DashLayout from "./components/DashLayout.js";

export function BoardingRoutes() {
    return(
        <>
    <Routes>

    <Route path="goal">
                <Route index element={<Goal />} />
                </Route>
                <Route path="fidelityBrokerage">
                    <Route index element={<FidelityBrokerage />} />
                </Route>

                <Route path="fidelityRothIRA">
                    <Route index element={<FidelityRothIRA />} />
                </Route>

                <Route path="chooseAmount">
                    <Route index element={<BankTrans />} />
                </Route>
                <Route path="connectBank">
                    <Route index element={<BankAccount />} />
                </Route>

                <Route path="fundingYourFuture">
                   <Route index element={<FundingYourFuture/>}/> 
                </Route>

    </Routes>
    </>
    )
    
    

}