import { useTranslation } from "react-i18next";
import HomePage from './components/Landingpage/HomePage';

import { Link, Route, Routes } from 'react-router-dom';
// import './App.css';
import './index.css'
import Errorpage from './components/404'
import Layout from './components/layout'

import DashLayout from "./components/DashLayout";
import Welcome from "./components/Onboarding/Step0/Welcome";
import UsersList from "./features/users/UsersList";
import EditUser from "./features/users/editUser";
import NewUserForm from "./features/users/NewUserForm";
import Prefetch from "./features/auth/Prefetch";
import PersistLogin from "./features/auth/PersistLogin";
import { ROLES } from "./config/roles";
import RequireAuth from "./features/auth/RequireAuth";
import RequireOnboard from "./features/auth/RequireOnboard";

import Homescreen from "./components/MVP/homescreen";
import { PublicRoutes } from "./PublicRoutes.js";
import { BoardingRoutes } from "./BoardingRoutes.js";

// import DashLayout from "./components/DashLayout.js";

function App() {

    const { t } = useTranslation()

    return (
        <>
            <div>
                <Routes>

                    <Route path="/" element={<Layout />}>
                        {/* public routes */}
                        <Route index element={<HomePage />} />
                        <Route path="/*" element={<PublicRoutes />} />

                        {/* protected Routes */}
                        <Route element={<PersistLogin />}>
                            {/* here add the element you want to be allowed access  */}
                            <Route element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}>
                                <Route element={<Prefetch />}>
                                    <Route path="/dash" element={<DashLayout />}>
                                        <Route index element={<Welcome />} />
                                        <Route path="/dash/*" element={<BoardingRoutes />} />
                                        <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                                            <Route path="users">
                                                <Route index element={<UsersList />} />
                                                <Route path=":id" element={<EditUser />} />
                                                <Route path="new" element={<NewUserForm />} />
                                            </Route>
                                        </Route>
                                        {/* User's home page */}
                                    </Route>
                                    <Route element={<RequireOnboard />}>
                                            <Route path="home" element={<DashLayout />}>
                                                <Route index element={<Homescreen />} />
                                            </Route>
                                    </Route>
                                </Route>
                            </Route>
                        </Route>
                        {/* end protected route */}
                    </Route>   {/* { Private, Public structure } */}
                    <Route path="*" element={<Errorpage />} />
                </Routes>
            </div>
        </>
    );
}

export default App;
