// uses BEM naming convention

import React, {useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFileCirclePlus,
    faFilePen,
    faUserGear,
    faUserPlus,
    faRightFromBracket
} from "@fortawesome/free-solid-svg-icons"
import { useNavigate, Link, useLocation } from 'react-router-dom'

import logo from './Finturalogo.svg'
import Langbtn from './langbtn'

import { useSendLogoutMutation } from '../features/auth/authAPIslice'
import useAuth from '../hooks/useAuth'

const DASH_REGEX = /^\/dash(\/)?$/
const NOTES_REGEX = /^\/dash\/notes(\/)?$/
const USERS_REGEX = /^\/dash\/users(\/)?$/

const DashHeader = () => {

    const { isAdmin, signUpStatus } = useAuth()
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const [sendLogout, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useSendLogoutMutation()

    useEffect(() => {
        if (isSuccess) navigate('/')
    }, [isSuccess, navigate])

    const onUsersClicked = () => navigate('dash/users')
    const onNewUserClicked = () => navigate('/dash/users/new')

    // if (isLoading) return <p>Logging Out...</p>

    // if (isError) return <p>Error: {error.data?.message}</p>

    let dashClass = null

    // making sure we're not on the root or users list
    
    if (!DASH_REGEX.test(pathname) && !NOTES_REGEX.test(pathname) && !USERS_REGEX.test(pathname)) {
        dashClass = "dash-header__container--small"
    }

    let newUserButton = null
    if (USERS_REGEX.test(pathname)) {
        newUserButton = (
            <button
                className="icon-button"
                title="New User"
                onClick={onNewUserClicked}
            >
                <FontAwesomeIcon icon={faUserPlus} />
            </button>
        )
    }

    let userButton = null
    if (isAdmin) {
        if (!USERS_REGEX.test(pathname) && pathname.includes('/dash')) {
            userButton = (
                <button
                    className="icon-button"
                    title="Users"
                    onClick={onUsersClicked}
                >
                    <FontAwesomeIcon icon={faUserGear} />
                </button>
            )
        }
    }

    const logoutButton = (
        <button 
            className="icon-button gap-x-1.5 rounded-md mr-1 bg-white px-3 py-1.5 text-base text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 font-semibold"
            title="Logout"
            onClick={sendLogout}
        >
            <FontAwesomeIcon icon={faRightFromBracket} />
            Logout
        </button>
    )

    const errClass = isError ? "errmsg" : "offscreen"
        
    let buttonContent
    if (isLoading) {
        buttonContent = <p>Logging Out...</p>
    } else {
        buttonContent = (
            <>
                {newUserButton}        
                {userButton}
                {logoutButton}
            </>
        )
    }

    // below define buger, close logic

    let [open,setOpen]=useState(false);

    const logoLink = signUpStatus === 4 ? '/home' : '/dash';

    const content = (
        <>
        <p className='{errClass}'>{error?.data?.message}</p>
        <header className="dash-header bg-red-300 fixed top-0 left-0 right-0 z-50 w-screen">
        <div className={`side-galaxy:static w-screen flex z-10 side-galaxy:col-span-2 side-galaxy:row-span-1 bg-gray-200 items-center justify-center  py-1 side-galaxy:py-0 h-16 md:h-18 `}>
        <div className={`flex rounded-xl items-center justify-between bg-white  md:px-10 px-4  ${dashClass}`} style={{height : "85%", width: "99%"}}>
        {/* <div className={`dash-header__container`}> */}
                <Link to={logoLink}>
                {/* <Link to={`/dash${signUpStatusIsFour ? '/home' : ''}`}> */}

                    <div className='font-bold text-2x1 cursor-pointer flex items-center font-Walsh2 text-gray-800 pt-2.5 md:pt-0'>
                        <div className=''>
                            <img className='h-32 w-32 mr-1.5' alt='Finturo logo' src={logo}></img>
                        </div>
                        {/* <p className="" style={{fontSize: '1.875rem'}}>
                           Finturo
                        </p> */}
                    </div>
                </Link>
                {/* <Link to="/dash/fidelity">
                    <h1 className="dash-header__title">Create Finturo Account</h1>
                </Link> */}
                <div onClick={()=>setOpen(!open)} className=' absolute right-8 top-4 md:top-3.5 cursor-pointer md:hidden' style={{fontSize: '1.875rem'}}>
                    
                    <ion-icon name={open ? 'close':'menu'}></ion-icon>
                </div>
                <ul
                // ease-in
                  className={`md:flex md:items-center md:pb-0 pb-12 fixed md:static md:z-auto z-[20] right-0 w-full md:w-auto md:pl-0 
                   duration-1000 
                    ${open ? 'top-0  h-full bg-FinanzaGreen md:bg-white' : ' top-[-800px]'}  `}>
                      <div onClick={()=>setOpen(!open)} className=' absolute right-8 top-3.5 cursor-pointer md:hidden' style={{fontSize: '1.875rem'}}>
                    <ion-icon name={open ? 'close':''}></ion-icon>
                     </div>
                     <div className={`${open ? 'flex flex-col pt-10 gap-y-8 items-center relative top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-3/4' : ''} `} >
                     {/* <Navbtn></Navbtn>   */}
                     <Langbtn></Langbtn> 
                      {/* <button className={`${open ? ' text-2xl text-gray-950' : 'inline-flex justify-center gap-x-1.5 rounded-md mr-1 bg-white px-3 py-1.5 text-base text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 '} font-semibold`}>
                        <Link to="/FAQ" className={`${open ? '' : ''} hover:text-gray-700`}>
                            {open ? 'Frequently Asked Questions' : 'FAQ'}
                        </Link>
                      </button> */}
                      {/* <div className="dash-header__nav"> */}
                    {/* add more buttons later */}
                        {buttonContent}
                    {/* </div> */}
                     </div>

                     
                </ul>
                
            </div>
            </div>
            
        </header>
        </>
        
    )

    return content
}
export default DashHeader