import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import useAuth from '../../../hooks/useAuth';
import { useUpdateUserMutation } from "../../../features/users/usersApiSlice"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"


const FidelityPlan = () => {



    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const navigate = useNavigate()
    const { id } = useAuth()

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };
    const signUpStatus = 4;
    // const navigate = useNavigate()

    const [updateUser, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateUserMutation()


    useEffect(() => {
        if (isSuccess) {
            // setUsername('')
            // setPassword('')
            // setRoles([])
            navigate('/home')
        }
    }, [isSuccess, navigate])

    const onAccept = async (e) => {
        const submitButton = document.querySelector('#accept');
        // const form = document.querySelector('#form');
        e.preventDefault()
        submitButton.disabled = true;
        submitButton.innerHTML = "Loading..."
        await updateUser({ id, signUpStatus })
        // navigate('/home', { state: { updatedSignUpStatus: signUpStatus } }); // Pass updatedSignUpStatus

    }

    const content = (
        <>
            <div className='flex flex-col justify-center items-center h-screen px-4 relative'>
                {isPopupOpen && (
                    <div className="popup absolute z-50 flex flex-col justify-center items-center ">
                        <div className="popup-content border border-slate-900 rounded bg-gray-200 h-96 ">
                            <button onClick={togglePopup} className="popup-close-button">×</button>
                            <h2 className="text-xl font-medium  text-center">Terms and Conditions</h2>
                            <p className='text-sm text-center mb-2'>Last Updated 9/19/2023 </p>
                            <p className='pl-2'>
                                I understand my investments are not-FDIC insured, and that the amount I invest may lose value. Finturo Co. will act in my best interests
                                to protect the underlying value of my investments, and holds a fiduciary responsibility to me.
                                {/* Add your terms and conditions text here */}
                            </p>
                            <div className='w-full flex justify-center'>
                                <Link to="/home">
                                    <button className='bg-FinanzaGreen py-2 px-8 rounded-xl text-white '
                                        id="accept"
                                        type='submit'
                                        onClick={onAccept} >
                                        I accept
                                    </button>
                                </Link>
                            </div>

                        </div>
                    </div>
                )}
                <div className='text-center md:w-3/4'>
                    <h1 className='text-lg md:text-2xl font-medium'> We believe <span className='text-FinanzaOrange'>$50 a month  </span> will be
                        a great start for your retirement goals</h1>
                    {/* <h1 className=' '> will be a great option for your retirement goals</h1> */}
                </div>

                <div>
                    <div class="py-6 md:py-12 relative ">
                        <div className='absolute top-10 -right-[6.5rem] flex '>
                            <div className='w-16 flex justify-between pt-5'>
                                <div className='bg-gray-400 h-1 w-1'> </div>
                                <div className='bg-gray-400 h-1 w-1'> </div>
                                <div className='bg-gray-400 h-1 w-1'> </div>
                                <div className='bg-gray-400 h-1 w-1'> </div>
                                <div className='bg-gray-400 h-1 w-1'> </div>
                                <div className='bg-gray-400 h-1 w-1'> </div>
                                <div className='bg-gray-400 h-1 w-1'> </div>
                                <div className='bg-gray-400 h-1 w-1'> </div>
                            </div>
                            <div className='text-sm flex flex-col items-center pr-2'>
                                <h1 > 85%</h1>
                                <h1 className=''> stock</h1>
                            </div>
                        </div>
                        <div class="w-32 h-32 rounded-full border-8  border-orange-500 relative overflow-hidden relative overflow-visible text-center flex justify-center items-center ">
                            <h1 className='absolute  z-20'> Your Retirement Plan</h1>
                            <div className='w-28 h-28 rounded-full bg-white z-10 '>
                            </div>
                            <div className='w-[4rem] h-[4rem] rounded-full border-8 border-FinanzaGreen absolute -bottom-2 -left-2 z-0'
                                style={
                                    {
                                        borderRadius: "0 0 0 300px"
                                    }

                                }>
                            </div>
                        </div>
                        <div className='absolute bottom-10 -left-[6.5rem] flex '>
                            <div className='text-sm flex flex-col items-center pr-2'>
                                <h1 > 15%</h1>
                                <h1 className=''> cash</h1>
                            </div>
                            <div className='w-16 flex justify-between pt-5'>
                                <div className='bg-gray-400 h-1 w-1'> </div>
                                <div className='bg-gray-400 h-1 w-1'> </div>
                                <div className='bg-gray-400 h-1 w-1'> </div>
                                <div className='bg-gray-400 h-1 w-1'> </div>
                                <div className='bg-gray-400 h-1 w-1'> </div>
                                <div className='bg-gray-400 h-1 w-1'> </div>
                                <div className='bg-gray-400 h-1 w-1'> </div>
                                <div className='bg-gray-400 h-1 w-1'> </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='flex flex-col text-sm gap-y-2 md:text-lg'>
                    <div className='flex gap-x-2 items-center'>
                        <div className='w-4 h-4 bg-FinanzaGreen rounded-sm drop-shadow-md'> </div>
                        <p> 24/7 Personalized Financial Plan & Advice </p>
                    </div>
                    <div className='flex gap-x-2 items-center'>
                        <div className='w-4 h-4 bg-FinanzaGreen rounded-sm drop-shadow-md'> </div>
                        <p> No More Extra Work on Your Part</p>
                    </div>
                    <div className='flex gap-x-2 items-center'>
                        <div className='w-4 h-4 bg-FinanzaGreen rounded-sm drop-shadow-md'> </div>
                        <p> Cancel Anytime With No Hidden Fees</p>
                    </div>
                </div>
                <div>
                    <Link>
                        <button onClick={togglePopup} className='mt-8 py-2 px-8 rounded-lg bg-FinanzaGreen text-white text-lg font-medium'
                            disabled={isPopupOpen}>
                            Sign Up </button>
                    </Link>
                </div>
            </div>
        </>
    )
    return content
}
export default FidelityPlan